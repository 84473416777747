<template>
    <div class="portal_pages">
        <div class="result_wpr new">
            <div class="actions mt-2">
                <ul>
                    <li class="filter_btn">
                        <div class="btn_item pointer" :class="params.is_private == 0 ? 'active' : ''" @click="filterPublicPrivatePage(0)">Public</div>
                        <div class="btn_item pointer" :class="params.is_private == 1 ? 'active' : ''" @click="filterPublicPrivatePage(1)">Private</div>
                    </li>
                    <li class="optionDrops search_area" :class="{'active': searchField}">
                        <input type="text" placeholder="Search" :disabled="assetsLoader" ref="search" @input="isTyping = true" v-model.trim="params.search" />
                        <button class="search_btn" @click="searchField = !searchField;">
                            <i class="fas fa-search"></i>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
        <div v-if="pages.total" class="list_container">
            <ul class="client_productList">
                <li v-for="(page, p) of pages.data" :key="p">
                    <div class="product_card">
                        <img v-if="page.dashboard_thumb" :src="page.dashboard_thumb" alt="" class="product_thumb">
                        <img v-else src="@/assets/images/thumb/default-page.svg" alt="" class="product_thumb">
                        <div class="product_cont">
                            <h4 class="pointer">{{ page.title }}</h4>
                            <p>{{ page.description && page.description.length > 150 ? page.description.substr(0, 150)+'...' : page.description }}</p>
                        </div>
                    </div>
                </li>
            </ul>
            <div class="pagination mt-2">
                <pagination v-model="params.page" :pages="pages.last_page" @update:modelValue="handlePagination" />
            </div>
        </div>
        <div class="empty_section mb-0" v-else>
            <h1 class="text-center">You Don't Have Any Pages To Display</h1>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'Client Portal Pages',

        data () {
            return {
                searchField: false,
                params: {
                    per_page: 8,
                    page: 1,
                    is_private: 0,
                    search: '',
                },
                isTyping: false,
            };
        },

        watch: {
            'params.search' (val) {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2) {
                            vm.params.page = 1;
                            vm.getPages(vm.params);
                        }
                        if (vm.params.search.length === 0) {
                            vm.params.page = 1;
                            vm.getPages(vm.params);
                        }
                    }
                }
            },
        },

        computed: mapState({
            pages: state => state.clientPortalModule.pages,
            assetsLoader: state => state.clientPortalModule.assetsLoader,
        }),

        mounted () {
            const vm = this;

            if (!vm.pages.total) {
                vm.getPages(vm.params);
            } else {
                vm.params.page = vm.pages.current_page;
            }
        },

        methods: {
            ...mapActions({
                getPages: 'clientPortalModule/getPages',
            }),

            handlePagination (page) {
                const vm = this;

                vm.params.page = page;
                vm.getPages(vm.params);
            },

            filterPublicPrivatePage (isPrivate) {
                const vm = this;

                vm.params.page = 1;
                vm.params.is_private = isPrivate;
                vm.getPages(vm.params);
            },
        },
    }
</script>

<style scoped>
    .portal_pages{
        margin-bottom: 40px;
    }
    .portal_pages .client_productList {
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 20px 0;
        gap: 30px;
    }

    .portal_pages .client_productList li {
        width: calc(50% - 15px);
    }

    .portal_pages .client_productList .product_card {
        width: 100%;
        display: flex;
        flex-direction: row;
        overflow: hidden;
        /* padding: 0 20px; */
    }

    .portal_pages .client_productList .product_thumb {
        flex: 0 0 180px;
        width: 180px;
        height: 140px;
        object-fit: cover;
        border-radius: 0;
    }

    .portal_pages .client_productList .product_card .product_cont {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .portal_pages .client_productList .product_card .product_cont h4 {
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
        color: #121525;
        margin-bottom: 12px;
    }

    .portal_pages .client_productList .product_card .product_cont p {
        font-size: 13px;
        line-height: 18px;
        font-weight: 300;
        color: #4b4b4b;
        margin: 0;
    }

    .inner_pages .actions .filter_btn {
        padding: 0;
        display: flex;
        gap: 30px;
    }

    .inner_pages .actions .filter_btn .btn_item {
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #5a5a5a;
        position: relative;
        transition: all 0.3s ease-in-out;
    }

    .inner_pages .actions .filter_btn .btn_item.active {
        color: #121525;
        font-weight: 500;
    }
    .empty_section {
        border: 1px solid #D9D9D9;
        border-radius: 10px;
        height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 80px;
    }

    .empty_section h1 {
        font-size: 25px;
        line-height: 32px;
        font-weight: 300;
        color: #757575;
        margin-bottom: 30px;
    }
    .result_wpr.new .actions{
        margin-bottom: 15px;
    }
    @media(max-width: 1199px){
        .result_wpr.new .actions > ul{
            height: 51px;
        }
        .portal_pages .client_productList .product_card .product_cont{
            padding: 15px;
        }
        .portal_pages .client_productList .product_thumb{
            flex: 0 0 150px;
            width: 150px;
            height: 120px;
        }
        .portal_pages .client_productList .product_card .product_cont h4{
            font-size: 14px;
            line-height: 19px;
        }
        .portal_pages .client_productList .product_card .product_cont p{
            font-size: 12px;
            line-height: 16px;
        }
    }
    @media(max-width: 767px){
        .portal_pages .client_productList{
            padding: 0;
        }
    }
    @media(max-width: 650px){
        .portal_pages .client_productList{
            padding: 0;
        }
        .portal_pages .client_productList li{
            flex: 0 0 100%;
        }
    }
    @media(max-width: 650px){
        .list_container{
            max-width: 450px;
            margin: 0 auto;
        }
    }
    @media(max-width: 499px){
        .inner_pages .actions .filter_btn{
            gap: 20px;
            padding-left: 5px;
        }
        .inner_pages .actions .filter_btn .btn_item{
            font-size: 13px;
            line-height: 18px;
        }
    }
    @media(max-width: 450px){
        .list_container{
            max-width: 350px;
            margin: 0 auto;
        }
        .result_wpr.new .actions > ul li.search_area{
            width: inherit;
        }
        .result_wpr.new .actions > ul li.search_area input[type=text]{
            width: 0;
        }
        .result_wpr.new .actions > ul li.search_area.active input[type=text]{
            width: 150px;
        }
    }
</style>
